import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as React from "react";

export const QuantitySelect = (props) => {
    return <Select
        id="productQuantity-select"
        value={props.externalProductQuantity}
        size={'small'}
        onChange={(e, value) => props.setExternalProductQuantity(e.target.value)}
    >
        {
            Array.from({length: 20}, (_, i) => i + 1)
                .map((qty) => {
                return <MenuItem value={qty}>{qty}</MenuItem>
            })
        }
    </Select>
}