import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import React, {useEffect} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import RadioGroup from "@mui/material/RadioGroup";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import moment from "moment";
import {useParams} from "react-router";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

function TiresDialog(props) {
    let params = useParams();

    const { onClose, value: valueProp, open, ...other } = props;
    const radioGroupRef = React.useRef(null);

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };

    useEffect(() => {
        window.addEventListener('message', message => {
            if(['https://6plus.bg', 'http://localhost:3000'].includes(message.origin)) {
                if (message.data) {
                    if (message.data.productName && message.data.productQuantity) {
                        // console.log(message)
                        props.setExternalProductName(message.data.productName)
                        props.setExternalProductQuantity(message.data.productQuantity)
                        props.setExternalProductPrice(message.data.productPrice)
                        props.setExternalProductImage(message.data.productImg)
                        onClose()
                    }
                }
            }
        });

    }, []);

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '100%', height: '90vh', marginLeft: 1, marginRight: 1 } }}
            maxWidth="xl"
            TransitionProps={{ onEntering: handleEntering }}
            open={open}
            {...other}
        >
            <DialogTitle>Гуми</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                })}
            >
                <CloseIcon />
            </IconButton>

            <DialogContent dividers style={{paddingLeft: 1, paddingRight: 1}}>
                <iframe style={{width: '100%', height: '97vh'}} src={'https://6plus.bg/category/gumi?fromReservation=true'}/>
            </DialogContent>

        </Dialog>
    );
}
export default TiresDialog;