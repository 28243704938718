import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from "@mui/x-date-pickers";
import moment from "moment";
import {useEffect} from "react";
import bg from 'moment/locale/bg'
import {lulin, mladost} from "./DepartamentsInfo";
import SelectFirstDialog from "./SelectFirstDialog";
import Slide from "@mui/material/Slide";
import MessageDialog from "./MessageDialog";
import Button from "@mui/material/Button";
import TiresDialog from "./TiresDialog";
import FormHelperText from "@mui/material/FormHelperText";
import {useSearchParams} from "react-router-dom";
import {QuantitySelect} from "./QuantitySelect";

export default function PaymentForm(props) {
    moment.locale("bg"); // it is required to select default locale manually
    const [searchParams, setSearchParams] = useSearchParams();


    const [isServiceObjectFocused, setServiceObjectFocused] = React.useState(true);
    const [isServiceFocused, setServiceFocused] = React.useState(false);
    const [isDateFocused, setDateFocused] = React.useState(false);
    const [isHourFocused, setHourFocused] = React.useState(false);

    const [availableHours, setAvailableHours] = React.useState([]);

    const [selectFirstText, setSelectFirstText] = React.useState(null);

    const departments = props.service === 1 ?
        lulin
        :
        mladost
    ;

    const [shouldDisableServicePicker, setShouldDisableServicePicker] = React.useState(false);
    const [tiresDialogOpen, setTiresDialogOpen] = React.useState(false);

    const isTiresSelected = props.serviceType === 1
    const isExternalProductApplied = props.externalProductName && props.externalProductQuantity
    const isExternalImageProduct = isExternalProductApplied && props.externalProductImage

    useEffect(() => {
        if (isExternalProductApplied) {
            props.setServiceType(1)
        }

    }, [isExternalProductApplied])

    const handleChange = (event) => {
        props.setServiceType(event.target.value);
        if (props.date !== null && props.date !== "") {
            props.setDate("");
        }
        props.setHourSelected("");
        setServiceObjectFocused( false);
        setServiceFocused( false);
        setDateFocused( true);
        setHourFocused( false);
        if (props.isHotel && props.setHotel && event.target.value !== 1) {
            props.setHotel(false);
        }
    };
    const handleDataChange = (newValue) => {
        setServiceObjectFocused( false);
        setServiceFocused( false);
        setDateFocused( false);
        setHourFocused( true);

        props.setDate(newValue);
        props.setHourSelected("");
        getAvailableHours(newValue);
    };
    const handleHourChange = (event) => {
        props.setHourSelected(event.target.value);
        props.handleNext();
    };

    useEffect(() => {
        if (props.serviceType && props.date && props.service) {
            getAvailableHours(props.date);
        }

        if (props.editMode && props.date) {
            getAvailableHours(props.date);
            setServiceObjectFocused(false)
            setServiceFocused( false);
            setDateFocused( true);
            setHourFocused( false);
        }
    }, [props.date])

    const getAvailableHours = (date, serviceId=props.service) => {
        const zeroPad = (num, places) => String(num).padStart(places, '0')

        const dateFormatted = date.year().toString() + "-" + zeroPad((date.month() + 1).toString(), 2) + "-" + zeroPad(date.date().toString(), 2)

        props.setFormattedDate(dateFormatted)
        fetch(`${process.env.REACT_APP_URL}/api/schedule?` + new URLSearchParams({
            id: props.serviceType,
            depid: props.serviceType,
            data: dateFormatted,
            serviceid: serviceId
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(response => response.json())
            .then(
                (result) => {
                    if (result && result.data) {
                        if (props.editMode && props.hourSelectedInitial) {
                            result.data.push({fhour: props.hourSelectedInitial, limit: 1, used: '0', isonline: '0', tmp: '30-Jun-2022 2:38'})
                        }

                        if (props.editMode) {
                            result.data.sort((a, b) => parseFloat(a.fhour) - parseFloat(b.fhour));
                        }

                        setAvailableHours(result.data)
                    }
                },
                (error) => {

                }
            );
    }

    const numberWithCommas = (x) => {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    return (
        <React.Fragment>
            
            <br/>
            <SelectFirstDialog
                text={selectFirstText}
                setText={setSelectFirstText}/>

            <Grid container spacing={3}>
                <>
                    {
                        (isExternalProductApplied) && <Grid item xs={isExternalImageProduct ? 9 : 12}>
                            <Typography>🛞 Запазихте: <QuantitySelect
                                externalProductQuantity={props.externalProductQuantity}
                                setExternalProductQuantity={props.setExternalProductQuantity}
                            /> бр. {props.externalProductName}{props.externalProductPrice ? ` - Цена - ${props.externalProductQuantity} X ${props.externalProductPrice} = ${numberWithCommas(Number(props.externalProductQuantity) * Number(props.externalProductPrice))} лв` : ''}</Typography>
                        </Grid>
                    }
                    {
                        isExternalImageProduct && <Grid item xs={3}>
                            <a href={props.externalProductImage} target={'_blank'}>
                                <img style={{width: '70%'}} src={props.externalProductImage}/>
                            </a>
                        </Grid>
                    }
                    </>

                {!props.editMode &&
                <Grid item xs={12}>
                    <FormControl fullWidth focused={isServiceObjectFocused} error={props.hotelOtherServiceOpen}>
                        <InputLabel id="demo-simple-select-label">Сервиз</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.service}
                            label="Сервиз"
                            disabled={props.editMode || shouldDisableServicePicker}
                            onChange={(event) => {
                                if (props.activeStep === 0) {
                                    props.setActiveStep(props.activeStep + 1)
                                }
                                if (!isServiceObjectFocused) {
                                    setDateFocused(false)
                                }

                                if (props.hotelOtherServiceOpen) {
                                    setShouldDisableServicePicker(true)
                                    getAvailableHours(props.date, event.target.value)
                                    props.setHourSelected('')
                                }

                                if (event.target.value !== props.service) {
                                    props.setHotelOtherServiceOpen(false)
                                }

                                setServiceObjectFocused(false)
                                setServiceFocused(true)
                                props.setService(event.target.value);
                            }}
                        >
                            <MenuItem value={1}>Люлин</MenuItem>
                            <MenuItem value={2}>Младост</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                }
                <Grid item xs={12}>
                    <Slide direction="up" in={!!props.service}>

                        <Grid container spacing={3}>
                            {(!!props.service && !props.editMode) &&
                                <>
                                    <Grid item xs={(isTiresSelected) ? 9 : 12}>
                                        <FormControl fullWidth focused={isServiceFocused}>
                                            <InputLabel id="demo-simple-select-label">Услуга</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={props.serviceType}
                                                label="Услуга"
                                                disabled={props.editMode || isExternalProductApplied}
                                                onChange={handleChange}
                                            >
                                                {
                                                    departments.map((department) => {
                                                        return <MenuItem value={department.id}>{department.name}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    {
                                        (isTiresSelected) && <Grid item xs={3}>
                                            <Button onClick={() => setTiresDialogOpen(true)}>
                                                Добави нови гуми
                                            </Button>
                                        </Grid>
                                    }

                                </>
                            }

                            {!!props.service &&
                                <Grid item xs={12}>
                                <FormControl fullWidth onClick={!props.serviceType ? () => {
                                    setSelectFirstText('Моля, изберете услуга!')
                                } : null}>

                                    <LocalizationProvider
                                        adapterLocale={'bg'}
                                        dateAdapter={AdapterMoment}>

                                        <MobileDatePicker
                                            label="Дата"
                                            inputFormat="DD MMM yyyy"
                                            value={props.date}
                                            minDate={(props.isHotel || isExternalProductApplied) ? moment().add(2, 'days')
                                                : props.serviceType === 1 ? moment().add(1, 'days') : moment()}
                                            closeOnSelect={true}
                                            disabled={!props.serviceType}
                                            onChange={handleDataChange}
                                            renderInput={(params) => <TextField focused={isDateFocused} {...params} />}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                            }

                    {/*<Grid item xs={12}>
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <TimePicker
                                    label="Избери час"
                                    value={date}
                                    minutesStep={15}
                                    shouldDisableTime={(timeValue, clockType) => {
                                        console.log(clockType, " " , timeValue)
                                        if (timeValue === 30) {
                                            return true;
                                        }
                                        return false;
                                    }}
                                    onChange={(newValue) => {
                                        setDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>*/}

                            {!!props.service &&
                            <Grid item xs={12}>
                                <FormControl fullWidth focused={isHourFocused}
                                             onClick={!props.serviceType || !props.date ? () => {
                                                 const text = !props.serviceType ? 'Моля, изберете услуга!' : 'Моля, изберете дата!';
                                                 setSelectFirstText(text)
                                             } : null}>
                                    <InputLabel id="demo-simple-select-label">Час</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={props.hourSelected}
                                        label="Час"
                                        disabled={!props.date}
                                        onChange={handleHourChange}
                                    >
                                        {
                                            availableHours && availableHours.map((hour) => {
                                                if (Number(hour.used) < Number(hour.limit)) {
                                                    return <MenuItem value={hour.fhour}>{hour.fhour}</MenuItem>
                                                }
                                            })
                                        }

                                        {
                                            (!availableHours || availableHours.length === 0 || (availableHours && availableHours.filter((hour) => {return Number(hour.used) < Number(hour.limit)}).length === 0)) ? <MenuItem disabled>Този ден няма свободен час!</MenuItem> : null
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            }
                        </Grid>
                    </Slide>
                </Grid>
            </Grid>

            {tiresDialogOpen && <TiresDialog open={tiresDialogOpen} onClose={() => setTiresDialogOpen(false)}
                                             setExternalProductName={props.setExternalProductName}
                                             setExternalProductQuantity={props.setExternalProductQuantity}
                                             setExternalProductPrice={props.setExternalProductPrice}
                                             setExternalProductImage={props.setExternalProductImage}
            />}
        </React.Fragment>
    );
}